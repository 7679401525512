import OSS from 'ali-oss';
const Client = {};

/**
 * 初始化阿里云上传控件
 * 1. 创建并返回Client对象
 */
Client.initOSSClient = policy => {
    const {region, accessKeyId, accessKeySecret, securityToken, bucket} = policy;
    return new OSS({
        region,
        accessKeyId,
        accessKeySecret,
        stsToken: securityToken,
        bucket,
        secure: true,
    });
};

/**
 * jsonObject 转 Blob
 * @returns Blob {Object}
 */
Client.StringObjToBlob = str => {
    return new Blob([str], {
        type: 'text/x-c-script',
    });
};
/**
 * Blob 转 file
 * @returns File {Object}
 */
Client.BlobToFile = (blob, fileName) => {
    return new File([blob], fileName, {type: blob.type});
};

// 开始上传
Client.startUpload = (file, ossKey, fileId, client, events) => {
    try {
        client
            .multipartUpload(ossKey, file, {
                progress: function (p, cpt, res) {
                    events.onProgress(file, fileId, p);
                },
            })
            .then(result => {
                events.onSuccess(file, fileId, result);
            })
            .catch(error => {
                events.onError(file, fileId, error);
            });
    } catch (err) {
        if (client.isCancel()) {
            console.log('-- 取消上传 --');
        }
        if (err.code === 'ConnectionTimeoutError') {
            console.log('Woops,Woops ,timeout error!!!');
        }
    }
};

export {Client};
