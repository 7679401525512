import $ from 'jquery';

var ProxyCreateSingleton_Api = (function () {
    var instance;
    return function () {
        if (instance) {
            return instance;
        }
        return (instance = new Api());
    };
})();
var ProxyCreateSingleton_Store = (function () {
    var instance;
    return function () {
        if (instance) {
            return instance;
        }
        return (instance = new Store());
    };
})();

function Api() {
    const hostname = window.location.hostname;
    const isTest = hostname.startsWith('test-') || hostname.startsWith('localhost');
    const isGray = hostname.startsWith('gray-');
    if (isTest) {
        return (this.requestUrl = 'https://qa-stem86-api.stem86.com');
    } else if (isGray) {
        return (this.requestUrl = 'https://gray-stem86-api.stem86.com');
    } else {
        return (this.requestUrl = 'https://api.stem86.com');
    }
}

/**
 * 获取oss凭证
 */
Api.prototype.getCppUploadPolicy = function () {
    const {user} = store.$global_store;
    return $.ajax({
        type: 'POST',
        url: this.requestUrl,
        contentType: 'text/plain',
        data: JSON.stringify({
            func: 'getCppUploadPolicy',
            token: user.token
        }),
        cache: false
    });
};

/**
 * @param {*} params -
 *   id：int，project的id，0表示新增
 *   fileName：string，对应oss的key（文件名部分建议是uuid或者文件内容的md5）
 *   title：string，作品标题
 *   description：string，作品描述
 */
Api.prototype.updateCppProject = function (params) {
    const {user} = store.$global_store;
    return $.ajax({
        type: 'POST',
        url: this.requestUrl,
        contentType: 'text/plain',
        data: JSON.stringify({
            func: 'updateCppProject',
            token: user.token,
            project: params
        })
    });
};

/**
 *
 * @param {*} pageIndex
 * @param {*} pageSize
 */
Api.prototype.getCppProjectListByAuthor = function (pageIndex, pageSize) {
    const {user} = store.$global_store;
    return $.ajax({
        type: 'POST',
        url: this.requestUrl,
        contentType: 'text/plain',
        data: JSON.stringify({
            func: 'getCppProjectListByAuthor',
            token: user.token,
            pageIndex: pageIndex || 0,
            pageSize: pageSize || 20
        }),
        async: false,
        cache: false
    });
};

/**
 *
 * @param {*} id
 * @returns
 */
Api.prototype.deleteCppProject = function (id) {
    const {user} = store.$global_store;
    return $.ajax({
        type: 'POST',
        url: this.requestUrl,
        contentType: 'text/plain',
        data: JSON.stringify({
            func: 'deleteCppProject',
            token: user.token,
            id: id
        }),
        cache: false
    });
};

Api.prototype.getSelectProject = function (url) {
    return $.ajax({
        type: 'GET',
        url: url,
        cache: true
    });
};

/**
 * @param {*} jqXHR
 *
 */
Api.prototype.codeBlock = function (jqXHR) {
    if (jqXHR.code !== 0) $.Toast('提示信息', jqXHR.message, 'warning');
    return jqXHR;
};

Api.prototype.errorBlock = function (jqXHR, textStatus, errorThrown) {
    if (errorThrown && jqXHR.status) {
        switch (jqXHR.status) {
            case 400:
                $.Toast('提示信息', '错误请求', 'warning');
                break;
            case 401:
                $.Toast('提示信息', '未授权，请重新登录', 'warning');
                break;
            case 403:
                $.Toast('提示信息', '拒绝访问', 'warning');
                break;
            case 404:
                $.Toast('提示信息', '请求错误,未找到该资源', 'warning');
                break;
            case 405:
                $.Toast('提示信息', '请求方法未允许', 'warning');
                break;
            case 408:
                $.Toast('提示信息', '请求超时', 'warning');
                break;
            case 500:
                $.Toast('提示信息', '服务器端出错', 'warning');
                break;
            case 501:
                $.Toast('提示信息', '网络未实现', 'warning');
                break;
            case 502:
                $.Toast('提示信息', '网络错误', 'warning');
                break;
            case 503:
                $.Toast('提示信息', '服务不可用', 'warning');
                break;
            case 504:
                $.Toast('提示信息', '网络超时', 'warning');
                break;
            case 505:
                $.Toast('提示信息', 'http版本不支持该请求', 'warning');
                break;
            default:
                $.Toast('提示信息', `连接错误${textStatus}`, 'warning');
        }
    } else {
        if (textStatus.includes('timeout')) {
            $.Toast('提示信息', '服务器响应超时，请刷新当前页', 'warning');
        }
        $.Toast('提示信息', '连接服务器失败', 'warning');
    }
};

/**
 * SessionStorage 的一些操作与监听
 */
function Store() {
    this.$global_store = {
        langValue: 'c_cpp',
        user: {},
        targetUrl: '0',
        CUploadPolicy: {},
        CJson: {
            pageIndex: 0,
            pageSize: 20,
            totalNumber: 0,
            totalPage: 1,
            dataList: []
        },
        handleCurrentPyFile: {
            createTime: 0,
            updateTime: 0,
            title: 'HelloC++',
            content: '// 请在下方输入你的C++代码!',
            description: '',
            url: '',
            id: 0 // 0表示新增
        },
        CdefaultFile: {
            createTime: 0,
            updateTime: 0,
            title: 'HelloC++',
            content: '// 请在下方输入你的C++代码!',
            description: '',
            url: '',
            id: 0 // 0表示新增
        }
    };
}
Store.prototype.watchEventSessionStorage = function () {
    var orignalSetItem = sessionStorage.setItem;
    sessionStorage.setItem = function (key, newValue) {
        var setItemEvent = new Event('setItemEvent');
        setItemEvent.newValue = newValue;
        window.dispatchEvent(setItemEvent);
        orignalSetItem.apply(this, arguments);
    };
    window.addEventListener('setItemEvent', function (e) {
        // eslint-disable-next-line no-constant-condition
        if ((e.key = 'store')) {
            var _this = sessionStorage.getItem('store');
            if (_this !== e.newValue) {
                if (e.newValue) {
                    // console.log('%c 正在更新SessionStorage', 'font-size:14px;color: pink; font-weight: 500;');
                    // console.log(JSON.parse(e.newValue));
                }
            }
        }
    });
};

Store.prototype.setStoreToSessionStorage = function () {
    let store = this.$global_store;
    sessionStorage.setItem('store', JSON.stringify(store));
};

Store.prototype.setSessionStorageToStore = function () {
    let store = this.getStoreSessionStorage();
    this.$global_store = store;
};

Store.prototype.clearStoreSessionStorage = function () {
    sessionStorage.removeItem('store');
};

Store.prototype.getStoreSessionStorage = function () {
    var store = sessionStorage.getItem('store');
    if (!store) return this.$global_store;
    else return JSON.parse(store);
};
/**
 * SessionStorage 的一些操作与监听 End
 */

const store = new ProxyCreateSingleton_Store();
store.watchEventSessionStorage();
store.setSessionStorageToStore();

const api = new ProxyCreateSingleton_Api();

export {api, store};
