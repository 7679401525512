/* eslint-disable no-unused-vars */
/* eslint-disable comma-dangle */
/* eslint-disable no-console */
import _ from 'underscore';
import iconFile from './images/icon-file.png';
import $ from 'jquery';
import {Utils} from './utils/util.js';
import {Client} from './ali-oss/ali-oss.js';
import {api as Api, store as Store} from './api_store.js';

export class EditorCopy {
    constructor(hub, state, container, editor) {
        this.container = container;
        this.editor = editor;
        this.domRoot = container.getElement();
        // 上传过程的回调
        this.fileUploadEvent = {
            onProgress: (file, fileId, p) => {
                console.log('上传进度:' + p);
            },
            onSuccess: (file, fileId, result) => {
                console.log(`%c 文件上传OSS成功, fileId: ${fileId}`, 'color: pink; font-weight: 500;background: #999;');
                console.log(result);
                const params = {
                    id: fileId,
                    fileName: result.name,
                    title: file.name,
                    description: ''
                };
                //(更新 / 上传) 作品
                this.updateCppProject(params);
            },
            onError: (file, fileId, error) => {
                $.Toast('提示信息', '保存失败', 'error');
                console.error(file, fileId, error);
            }
        };
        this.findDom();
    }

    findDom() {
        const saveToServerButton = this.domRoot.find('.action-savetoserver');
        const openWorkListModal = this.domRoot.find('.openWorkListModal');
        const createFileButton = this.domRoot.find('#actionCreatefile');
        this.domBindFunc(saveToServerButton, openWorkListModal, createFileButton);
    }

    // 方法: 获取凭证
    getCppUploadPolicy() {
        console.log('「」 获取oss凭证 「」');
        const that = this;
        var getCppUploadPolicy = Api.getCppUploadPolicy();
        getCppUploadPolicy.done(function (data) {
            console.log('%c 返回oss凭证: ', 'font-size: 14px; color: blue; font-weight: 500;');
            console.log(data);
            if (data.code === 0) {
                Store.$global_store.CUploadPolicy = data.result;
                Store.setStoreToSessionStorage();
                // 开始上传
                that.startUpload(data.result);
            }
        });
        getCppUploadPolicy.fail(function (jqXHR, textStatus, errorThrown) {
            Api.errorBlock(jqXHR, textStatus, errorThrown);
        });
        getCppUploadPolicy.always(function (jqXHR, textStatus, errorThrown) {
            Api.codeBlock(jqXHR);
        });
    }

    // 方法: 开始上传
    startUpload(policy) {
        const {handleCurrentPyFile, CJson} = Store.$global_store;
        const fileId = handleCurrentPyFile.id;
        const {_index, id} = Utils.searchInjson(CJson.dataList, fileId);
        let blob = Client.StringObjToBlob(handleCurrentPyFile.content);
        let file = Client.BlobToFile(
            blob,
            handleCurrentPyFile.title.indexOf('.cpp') > -1
                ? handleCurrentPyFile.title
                : handleCurrentPyFile.title + '.cpp'
        );
        let client = Client.initOSSClient(policy);
        /**
         * 1. if 修改已存在文件 (旧文件) 更新操作
         * 2. else 创建新文件(修改系统创建好的文件) 创建操作
         */
        if (_index !== -1 && id !== -1) {
            let ossKey = handleCurrentPyFile.fileName;
            Client.startUpload(file, ossKey, fileId, client, this.fileUploadEvent);
        } else {
            let ossKey = policy.directory + '/' + Utils.changeFileName(file);
            Client.startUpload(file, ossKey, fileId, client, this.fileUploadEvent);
        }
    }

    // 方法: 通知服务作品上传oss成功
    updateCppProject(params) {
        let updateCppProject = Api.updateCppProject(params);
        updateCppProject.done(function (data) {
            //请求成功的回调函数
            console.log('通知服务作品上传oss成功');
            console.log(data);
            let getCppProjectListByAuthor = Api.getCppProjectListByAuthor();
            getCppProjectListByAuthor.done(function (data) {
                if (data.code === 0) {
                    Store.$global_store.CJson = data.result;
                    Store.$global_store.handleCurrentPyFile = data.result.dataList[0];
                    Store.setStoreToSessionStorage();
                    $.Toast('提示信息', '保存成功', 'success');
                }
            });
        });
        updateCppProject.fail(function (jqXHR, textStatus, errorThrown) {
            //请求失败的回调函数
            Api.errorBlock(jqXHR, textStatus, errorThrown);
        });
        updateCppProject.always(function (jqXHR, textStatus, errorThrown) {
            //请求成功或失败都会回调的函数  // console.log(jqXHR, textStatus, errorThrown)
            Api.codeBlock(jqXHR);
        });
    }

    // 事件绑定
    domBindFunc(saveToServerButton, openWorkListModal, createFileButton) {
        // 事件: 保存
        saveToServerButton.on(
            'click',
            _.bind(e => {
                window.$sa.click({
                    click_element: 'WEB_PROGRAMMING_SAVE_BUTTON'
                });
                Store.$global_store.handleCurrentPyFile.title = $('#CurFileName').val() + '.cpp';
                Store.$global_store.handleCurrentPyFile.content = this.editor.getSource();
                this.getCppUploadPolicy();
            }, this)
        );

        // 事件: 打开作品列表
        openWorkListModal.on(
            'click',
            _.bind(e => {
                console.log('「」 打开作品列表 「」');

                createPaginationReqWorkList();
                const that = this;
                // 获取选择的作品
                function getSelectProject(item) {
                    var getSelectProject = Api.getSelectProject(item.url);
                    getSelectProject.done(function (data) {
                        console.log('获取所选作品文件：');
                        console.log(data);
                        /**
                         * 更新store，渲染code, 渲染文件名; 并关闭作品列表弹窗
                         */
                        that.editor.setSource(data);
                        $('#CurFileName').val(
                            item.title.indexOf('.cpp') > -1 ? Utils.getCaption(item.title, '.cpp', 0) : item.title
                        );
                        Store.$global_store.handleCurrentPyFile = item;
                        Store.$global_store.handleCurrentPyFile.content = data;
                        Store.setStoreToSessionStorage();
                        $('#workList').modal('hide');
                    });
                    getSelectProject.fail(function (jqXHR, textStatus, errorThrown) {
                        Api.errorBlock(jqXHR, textStatus, errorThrown);
                    });
                }
                // 绑定（删除 / 选择）事件
                function bind_deletePythonProject() {
                    let deletePythonProjectBtn = $('.delCppProject');
                    console.log('deletePythonProjectBtn:', deletePythonProjectBtn);
                    console.log('length:', deletePythonProjectBtn.length);
                    for (let i = 0; i < deletePythonProjectBtn.length; i++) {
                        $('#delCppProject-' + i).click(function (e) {
                            e.preventDefault();
                            e.stopPropagation();
                            var item = $(this).data('item');
                            console.log(
                                `%c 准备删除作品: ${item.id}`,
                                'color: #f38686; font-size:14px;font-weight: 500;'
                            );
                            console.log(item);
                            const {content, title} = Store.$global_store.CdefaultFile;
                            const {id} = Store.$global_store.handleCurrentPyFile;
                            confirm({
                                title: '提示',
                                content: item.id === id ? '确定删除当前所编辑文件吗？' : '是否确认要删除？',
                                doneText: '确认',
                                cancalText: '取消'
                            })
                                .then(() => {
                                    console.log('确认删除作品(id): ' + item.id);
                                    var deleteCppProject = Api.deleteCppProject(item.id);
                                    deleteCppProject.done(function (data) {
                                        if (data.code === 0) {
                                            //  请求成功的回调函数
                                            console.log('成功删除作品后返回的数据：');
                                            console.log(data);
                                            if (item.id === id) {
                                                that.editor.setSource(content);
                                                $('#CurFileName').val(
                                                    title.indexOf('.cpp') > -1
                                                        ? Utils.getCaption(title, '.cpp', 0)
                                                        : title
                                                );
                                                Store.$global_store.handleCurrentPyFile =
                                                    Store.$global_store.CdefaultFile;
                                                Store.setStoreToSessionStorage();
                                            }
                                            createPaginationReqWorkList();
                                        }
                                    });
                                    deleteCppProject.fail(function (jqXHR, textStatus, errorThrown) {
                                        //请求失败的回调函数
                                        Api.errorBlock(jqXHR, textStatus, errorThrown);
                                    });
                                    deleteCppProject.always(function (jqXHR, textStatus, errorThrown) {
                                        //请求成功或失败都会回调的函数  // console.log(jqXHR, textStatus, errorThrown)
                                        Api.codeBlock(jqXHR);
                                    });
                                })
                                .catch(() => {
                                    console.log('已取消');
                                });
                        });
                    }
                    let selectWork = $('.work');
                    for (let i = 0; i < selectWork.length; i++) {
                        $('#work-' + i).click(function (e) {
                            let item = $(this).data('item');
                            console.log(
                                `%c 选择的作品: ${item.id}`,
                                'color: #f38686; font-size:14px;font-weight: 500;'
                            );
                            console.log(item);
                            getSelectProject(item);
                        });
                    }
                }
                // 创建作品列表ui
                function createWorkListTemplate(Api, data) {
                    var html = '<ul class="fileListBox">';
                    // ${index % 2 === 0 ? 'li-margin' : ''}
                    $.each(data, function (index, item) {
                        html += `<li data-item='${JSON.stringify(item)}' class='work' id="work-${index}">
                        <span class="delCppProject" id="delCppProject-${index}" data-item='${JSON.stringify(
                            item
                        )}'>Ⅹ</span>
                        <img src="${iconFile}" alt="icon-file" class="icon-file"/>
                        <p class="info">
                            <span class="filename">${item.title}</span>
                            <span class="time">${Utils.formatDate(
                                'yyyy-MM-dd',
                                item.updateTime || item.createTime
                            )}</span>
                        </p>
                    </li>`;
                    });
                    html += '</ul>';
                    return html;
                }
                // 创建分页及请求作品列表
                function createPaginationReqWorkList() {
                    console.log($('.paginations'));
                    $('.paginations').pagination({
                        dataSource: function (done) {
                            let getCppProjectListByAuthor = Api.getCppProjectListByAuthor(0, 9999);
                            getCppProjectListByAuthor.done(function (data) {
                                //请求成功的回调函数
                                console.log('返回的数据：');
                                console.log(data);
                                if (data.code === 0) {
                                    $('#workList').modal('show');
                                    done(data.result.dataList);
                                    Store.$global_store.CJson = data.result;
                                    Store.setStoreToSessionStorage();
                                    if (data.result.dataList.length < 1) $('.noWorkListLengthTip').show();
                                    else {
                                        $('.noWorkListLengthTip').hide();
                                    }
                                }
                            });
                            getCppProjectListByAuthor.fail(function (jqXHR, textStatus, errorThrown) {
                                //请求失败的回调函数
                                Api.errorBlock(jqXHR, textStatus, errorThrown);
                            });
                            getCppProjectListByAuthor.always(function (jqXHR, textStatus, errorThrown) {
                                //请求成功或失败都会回调的函数  // console.log(jqXHR, textStatus, errorThrown)
                                Api.codeBlock(jqXHR);
                            });
                        },
                        pageSize: 20,
                        pageNumber: 1, // 初始化时指定页码
                        pageRange: 2, // 可见页码范围：默认2
                        showGoInput: true,
                        showGoButton: true,
                        autoHidePrevious: true,
                        autoHideNext: true,
                        goButtonText: '跳转',
                        callback: function (data, pagination) {
                            console.log('%c ～切换页码～', 'font-size: 14px; color: green; font-weight: 500;');
                            console.log(data, pagination);
                            var html = createWorkListTemplate(Api, data);
                            $('#fileList-container').html(html);
                            bind_deletePythonProject();
                        },
                        formatGoButton: function (button, currentPage, totalPage, totalNumber) {
                            console.log(
                                'currentPage => ' + currentPage,
                                'totalPage => ' + totalPage,
                                'totalNumber => ' + totalNumber
                            );
                        }
                    });
                }
            }, this)
        );

        // 事件: 创建新文件
        createFileButton.on(
            'click',
            _.bind(e => {
                console.log('「」 创建新文件 「」');
                $('#createFileWriteName').modal('show');
                const that = this;
                $('.createFileOK').on('click', function () {
                    var file_newname_val = $('#fileNameVal').val();
                    if (file_newname_val === '') {
                        $('.writeFileName .tip').show();
                    } else {
                        file_newname_val = file_newname_val.replace(/\s/g, '');
                        $('#CurFileName').val(file_newname_val);
                        console.log('新文件名：' + file_newname_val);
                        let newFileData = JSON.parse(JSON.stringify(Store.$global_store.CdefaultFile));
                        Store.$global_store.handleCurrentPyFile = newFileData;
                        Store.$global_store.handleCurrentPyFile.title = file_newname_val;
                        that.editor.setSource(newFileData.content);
                        // 获取凭证 -> 开始上传 ->  通知服务作品oss上传状态
                        that.getCppUploadPolicy();
                        $('.writeFileName .tip').hide();
                        $('#createFileWriteName').modal('hide');
                        $('#fileNameVal').val('');
                    }
                });

                $('#createFileWriteName').on('hide.bs.modal', function () {
                    $('.writeFileName .tip').style = 'display: none;';
                });
            }, this)
        );
    }
}
